
import {Component, Vue} from 'vue-property-decorator';
import {QPage} from 'quasar';

import {WsButton} from '@WS_Components/WsButton.vue';

@Component({
  components: {
    QPage,
    WsButton,
  },
})
export class Page404 extends Vue {
}

export default Page404;
